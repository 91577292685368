import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: true,
  globalInjection: true,
  locale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {}
});

function initI18n() {
  i18n.global.locale = window.$printos.i18n.v1.getShortLocale();
  i18n.locale = window.$printos.i18n.v1.getShortLocale();

  // window.addEventListener('pros-locale-changed', () => {
  //   i18n.locale = window.$printos.i18n.v1.getShortLocale();
  // });

  let promises = [];
  promises.push(getCatalog('uxf', 'portal_service'));
  promises.push(getCatalog('register', 'portal_service', 'start'));
  promises.push(getCatalog('application', 'portal_service', 'app'));
  return Promise.all(promises);
}

function getCatalog(catalogId, service = null, alias) {
  let myService = service ? service : undefined;
  let catalogEntry = alias ? alias : catalogId;
  let locale = window.$printos.i18n.v1.getShortLocale();
  return window.$printos.i18n.v1.getCatalogAsync(catalogId, myService).then(resp => {
    let messages = i18n.global.getLocaleMessage(locale);
    messages[catalogEntry] = resp;
    i18n.global.setLocaleMessage(locale, messages);
  });
}

export {i18n as default, initI18n};
